import React from "react";
import {ReactComponent as LODING} from "./lodingSvg.svg";
import "./Loding.css";


const Loding = () => {
    return (
        <LODING/>
    )
}

export default Loding